import { createI18n } from 'vue-i18n'
import messages from './language'

let _locale = 'en_US'

if (navigator.language.toLowerCase().indexOf('zh') !== -1) {
  _locale = 'zh_CN'
}

if (navigator.language.toLowerCase().indexOf('es') !== -1) {
  _locale = 'es_ES'
}

const i18n = createI18n({
  locale: _locale,
  fallbackLocale: 'en_US',
  messages,
  legacy: false
})

export default i18n
