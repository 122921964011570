import { computed, defineComponent, ref, onMounted, reactive, nextTick } from 'vue'
import { judgeScreenType } from '@/utils/helper'

export default defineComponent({
  name: 'PageLayout',
  props: {
    isBackgroundWhite: {
      required: false,
      type: Boolean,
      default: false
    },
    isSamePageFooter: {
      required: false,
      type: Boolean,
      default: true
    },
    isFooterBlack: {
      required: false,
      type: Boolean,
      default: false
    },
    isShowFooterIndicator: {
      required: false,
      type: Boolean,
      default: true
    },
    isFixedBottomNavigationBar: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const headerRef = ref<HTMLElement | null>(null)
    const footerRef = ref<HTMLElement | null>(null)
    const pageWidthRef = computed<string>(() => `${sessionStorage?.getItem('pageWidth') ?? document.body.clientWidth}px`)
    const isFullScreenRef = computed<boolean>(() => props.isShowFooterIndicator ? judgeScreenType() : props.isShowFooterIndicator)
    const pageBodyStyle = reactive({
      paddingTop: '44px',
      paddingBottom: '0px',
      height: props.isFixedBottomNavigationBar ? '100%' : '', // 底部导航栏始终存在
      overflowY: props.isFixedBottomNavigationBar ? 'auto' : '', // 底部导航栏始终存在
      minHeight: props.isFixedBottomNavigationBar ? '' : '100%' // 底部导航栏滚动时消失
    })

    const bodyBackground = computed<string>(() => props.isBackgroundWhite ? 'background-FFF' : 'background-EFEFEF')
    const footerBackground = computed<string>(() => {
      if (props.isFooterBlack) {
        return 'background-333'
      } else {
        if (props.isBackgroundWhite && props.isSamePageFooter) {
          return 'background-FFF'
        } else {
          return !props.isBackgroundWhite && props.isSamePageFooter ? 'background-EFEFEF' : ''
        }
      }
    })

    onMounted(async () => {
      await nextTick(() => {
        if (headerRef.value !== null) {
          pageBodyStyle.paddingTop = `${headerRef.value.clientHeight}px`
        }

        if (footerRef.value !== null) {
          if (footerRef.value.childElementCount >= 1 && footerRef.value.children[0].className !== 'menu-items-shop-cart-view') {
            const footerSpanEl = document.createElement('span')
            footerSpanEl.style.height = '10px'
            footerSpanEl.style.display = 'block'
            footerSpanEl.style.width = '100%'

            footerRef.value.appendChild(footerSpanEl)
          }

          setTimeout(() => {
            if (footerRef.value !== null) {
              pageBodyStyle.paddingBottom = `${footerRef.value.clientHeight}px`
            }
          }, 100)
        }
      })
    })

    return {
      headerRef,
      footerRef,
      pageBodyStyle,
      bodyBackground,
      footerBackground,
      isFullScreenRef,
      pageWidthRef
    }
  }
})
