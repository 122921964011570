import { Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-18-bold-000" }
const _hoisted_2 = { class: "footer-view" }
const _hoisted_3 = { class: "footer-view-left-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "common-modal-view",
    centered: _ctx.isCentered
  }, _createSlots({
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "footer")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "footer-left-right")
      ])
    ]),
    default: _withCtx(() => [
      (!_ctx.isTitleShow)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.isTitleShow)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, [
              _renderSlot(_ctx.$slots, "title")
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["centered"]))
}