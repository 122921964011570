import { computed, defineComponent } from 'vue'
import BackIcon from '@/assets/icon/back.svg'
import { Affix as AAffix } from 'ant-design-vue'
import RouterUtil from '@/utils/helper/RouterUtil'

export default defineComponent({
  name: 'PageNav',
  props: {
    isShowBack: {
      required: false,
      type: Boolean,
      default: true
    },
    isDefaultBack: {
      required: false,
      type: Boolean,
      default: true
    },
    isIconWhite: {
      required: false,
      type: Boolean,
      default: false
    },
    isBackgroundWhite: {
      required: false,
      type: Boolean,
      default: false
    },
    isBackgroundTrans: {
      required: false,
      type: Boolean,
      default: false
    },
    customBackgroundColor: {
      required: false,
      type: String,
      default: ''
    }
  },
  components: { BackIcon, AAffix },
  emits: ['toBack'],
  setup (props, { emit }) {
    const navBackground = computed<string[]>(() => {
      const basicClass = ['page-nav-view']
      if (props.isIconWhite) {
        basicClass.push('page-nav-white-icon')
      }

      if (props.isBackgroundWhite) {
        basicClass.push('page-nav-white-view')
      }

      if (props.isBackgroundTrans) {
        basicClass.push('page-nav-trans-view')
      }

      return basicClass
    })

    function toBack(): void {
      props.isDefaultBack ? RouterUtil.instance().back() : emit('toBack')
    }

    return {
      navBackground,
      toBack
    }
  }
})
