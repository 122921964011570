import { defineComponent, ref, nextTick, onMounted } from 'vue'

export default defineComponent({
  name: 'MainLayout',
  components: {},
  setup () {
    const mainLayoutHtmlRef = ref<HTMLElement | null>(null)
    sessionStorage.setItem('pageWidth', `${document.body.clientWidth}`)

    onMounted(async () => {
      await nextTick(() => {
        if (mainLayoutHtmlRef.value !== null) {
          sessionStorage.setItem('pageWidth', `${mainLayoutHtmlRef.value.clientWidth}`)
        }
      })

      window.addEventListener('resize', () => {
        if (mainLayoutHtmlRef.value !== null) {
          sessionStorage.setItem('pageWidth', `${mainLayoutHtmlRef.value.clientWidth}`)
        }
      })
    })

    return {
      mainLayoutHtmlRef
    }
  }
})
