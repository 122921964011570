import { getDatabase, ref, Database, DatabaseReference, child } from 'firebase/database'

/**
 * 堂食订单节点构建器
 *
 * 该类封装了Firebase实时数据库堂食订单节点的路径构建逻辑，提供链式访问接口。
 * 主要职责：
 * 1. 构建基于店铺ID和订单ID的订单根节点路径
 * 2. 提供订单各属性节点的链式访问方法
 * 3. 封装数据库节点路径的构建细节，保证路径一致性
 *
 * 结构设计：
 * - 根节点：dineInOrders/{shopId}/{autoId}
 * - 子节点包含：订单基本信息、提交记录、支付信息、餐桌信息等
 *
 * 核心功能：
 * - 订单元数据：创建时间/订单号/流水号（createTime/orderNumber/serialNumber）
 * - 提交管理：多批次提交记录（submissions）及其状态/备注/商品项
 * - 支付信息：支付记录（orderPayments）及其详细支付数据
 * - 餐桌信息：桌号/小费/用餐人数（tableInfo相关节点）
 * - 订单操作：订单合并/拆分状态（orderCombine/isOrderSplit）
 *
 * 使用场景：
 * - 创建新堂食订单时构建完整节点结构
 * - 更新订单状态时定位具体节点
 * - 查询订单数据时提供标准路径
 *
 * 注意事项：
 * 1. submission(index)方法需自行管理索引顺序
 * 2. 所有方法返回DatabaseReference需配合Firebase API使用
 * 3. 节点路径变更需同步更新此类方法
 * 4. 保持与数据库结构的严格对应关系
 */
export default class DineInOrderNodeBuilder {
  private readonly _shopId: string
  private readonly _autoId: string
  private readonly _db: Database

  constructor(shopId: string, autoId: string) {
    this._shopId = shopId
    this._autoId = autoId
    this._db = getDatabase()
  }

  orderNode(): DatabaseReference {
    return child(child(ref(this._db, 'dineInOrders'), this._shopId), this._autoId)
  }

  createTime(): DatabaseReference {
    return child(this.orderNode(), 'createTime')
  }

  orderNumber(): DatabaseReference {
    return child(this.orderNode(), 'orderNumber')
  }

  serialNumber(): DatabaseReference {
    return child(this.orderNode(), 'serialNumber')
  }

  submissions(): DatabaseReference {
    return child(this.orderNode(), 'submissions')
  }

  orderPayments(): DatabaseReference {
    return child(this.orderNode(), 'orderPayments')
  }

  tableInfo(): DatabaseReference {
    return child(this.orderNode(), 'tableInfo')
  }

  tableName(): DatabaseReference {
    return child(this.tableInfo(), 'tableName')
  }

  tableId(): DatabaseReference {
    return child(this.tableInfo(), 'tableId')
  }

  tableGratuities(): DatabaseReference {
    return child(this.tableInfo(), 'tableGratuities')
  }

  userCount(): DatabaseReference {
    return child(this.orderNode(), 'userCount')
  }

  dineInOrToGo(): DatabaseReference {
    return child(this.orderNode(), 'dineInOrToGo')
  }

  submission(index: number): DatabaseReference {
    return child(this.submissions(), `${index}`)
  }

  isSubmitted(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'isSubmitted')
  }

  submissionNotes(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'notes')
  }

  submissionItems(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'items')
  }

  submissionDineInOrTo(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'dineInOrToGo')
  }

  orderCombineCreateTime(): DatabaseReference {
    return child(child(this.orderNode(), 'orderCombine'), 'createTime')
  }

  isOrderSplit(): DatabaseReference {
    return child(this.orderNode(), 'isOrderSplit')
  }

  guestPayments(): DatabaseReference {
    return child(child(this.orderPayments(), '0'), 'guestPayments')
  }

  guestPayment(): DatabaseReference {
    return child(this.guestPayments(), '0')
  }

  isPaid(): DatabaseReference {
    return child(this.guestPayment(), 'isPaid')
  }

  total(): DatabaseReference {
    return child(this.guestPayment(), 'total')
  }

  tax(): DatabaseReference {
    return child(this.guestPayment(), 'tax')
  }

  tips(): DatabaseReference {
    return child(this.guestPayment(), 'tips')
  }

  gratuity(): DatabaseReference {
    return child(this.guestPayment(), 'gratuity')
  }

  serviceFee(): DatabaseReference {
    return child(this.guestPayment(), 'serviceFee')
  }

  payAmount(): DatabaseReference {
    return child(this.guestPayment(), 'payAmount')
  }

  payMethod(): DatabaseReference {
    return child(this.guestPayment(), 'payMethod')
  }

  userPointsDeduction(): DatabaseReference {
    return child(this.guestPayment(), 'userPointsDeduction')
  }
}
