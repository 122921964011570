import { getDatabase, ref, Database, DatabaseReference, child } from 'firebase/database'

export default class WaitlistNodeBuilder {
  private readonly _shopId: string
  private readonly _autoId: string
  private readonly _db: Database

  constructor(shopId: string, autoId: string) {
    this._shopId = shopId
    this._autoId = autoId
    this._db = getDatabase()
  }

  waitlist(): DatabaseReference {
    return child(child(ref(this._db, 'waitlists'), this._shopId), this._autoId)
  }

  createTime(): DatabaseReference {
    return child(child(this.waitlist(), 'waitlist'), 'createTime')
  }
}
