import { defineComponent } from 'vue'
import { Modal as AModal } from 'ant-design-vue'

export default defineComponent({
  name: 'CommonModal',
  components: { AModal },
  props: {
    isTitleShow: {
      required: false,
      type: Boolean,
      default: true
    },
    isCentered: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {}
  }
})
