export default {
  en_US: {
    loading: 'Loading...',
    ok: 'OK',
    cancel: 'Cancel',
    warning: 'Warning',
    error: 'Error',
    submit: 'Submit',
    save: 'Save',
    Subtotal: 'Subtotal',
    tax: 'Tax',
    tips: 'Tips',
    gratuity: 'Gratuity',
    dineIn: 'Dine In',
    toGo: 'To Go',
    requestFail: 'Oops, there is a network problem. Please try again',
    giftCardConfirmed: 'This gift card has already been claimed or declined',
    giftCardExpired: 'The claim time for this gift card has passed and it is no longer valid',
    couponAvailableToReturn: 'This coupon is exclusive for returning customers',
    couponAvailableToNew: 'This coupon is exclusive for new customers',
    shareLinkExpired: 'This link or coupon is no longer valid',
    shareLinkInvalid: 'This link is no longer valid',
    invalidLink: 'Invalid Yammii link',
    tableQRCodeNotEnabled: 'Scan to order is disabled',
    notInBusinessHours: 'This store is closed now',
    waitlistNotEnabled: 'Waitlist disabled',
    posWaitlistNotEnabled: 'This store has disabled waitlist feature',
    onlineOrderNotEnabled: 'This store has disabled online order',
    posOnlineOrderNotEnabled: 'This store has temperory disabled online order',
    payFail: 'Unable to process payment, please try again later',
    notSupportApplePay: 'Apple Pay is not compatible with your phone ',
    notSupportGooglePay: 'Google Pay is not compatible with your phone ',
    notSupportGiftCard: 'This restaurant does not accept gift cards',
    isPaying: 'We are processing your payment, please try again later',
    giftCardUnavailable: 'Gift Card is not available',
    cardNotVerified: 'Your credit card has not been verified',
    cvvVerifyFailed: 'The CVV code you entered is incorrect',
    cardPayFail: 'This transaction will be cancelled by your bank. Please correct the mistake or use a different card for the payment.',
    zipCodeVerifyFailed: 'The ZIP code you entered does not match your card information',
    cardCheckFailed: 'The credit card number you entered is incorrect',
    avsFailed: 'There was a problem with your address verification. Please check your card information and try again',
    avsCheckFailed: 'This transaction will be cancelled by your bank. Please check the expire date and CVV number or use another card.',
    cardNumberError: 'Please make sure your card information is correct or use another card.',
    accountClosed: 'Sorry, your account is closed, please use a different card for the payment',
    suspectedFraud: 'There is an issue with your card. Please use a different card or contact your bank for assistance.',
    insufficientFunds: ' You do not have enough funds in your account to complete this transaction',
    insufficientError: 'Please use a different card for the payment because this card is not supported by our system.',
    notHonor: 'Your bank declined this transaction. Please use a different card or contact your bank for more information.',
    restaurantClose: ' The store is closed for today. Please come back tomorrow. ',
    orderSubmitted: 'Your order has been successfully submitted',
    communicationError: 'There is a communication error with the server. Please try again later.',
    firebaseError: 'Oops! We are having some network issues. We are trying to restore our network communication. Please ask the server for help or try again later. ',
    priceMismatch: 'Due to a system error, your order price has changed. We apologize for the inconvenience. Please pay at the counter. ',
    cardTypeMismatch: 'There is a mismatch between your card type and your card number. Please select the correct card type and try again.',
    notEnoughPoint: 'You are almost there! You need more points to redeem this reward.',
    unavailableCoupon: 'This coupon is unavailable at this time',
    giftCardInvalid: 'Your gift card is invalid, Please ask the server for help or use a different payment method.',
    giftCardInsufficient: 'You have used up your gift card balance, Please ask the server for help or use a different payment method.',
    userAuthFailed: 'User authentication failed, please try again.',
    codeExpired: ' Your QR code has expired. Please scan a new QR code and try again',
    orderPaid: 'Your order has been checked out. Thank you for your purchase.',
    orderVoided: 'We’re sorry, your order has been voided.',
    close: 'Close',
    qrCodePayExpired: 'QRCode Pay Expired. Please scan a new code to proceed.',
    numberBetweenRange: 'Please enter an integer between {0} and {1}.',
    noNaN: 'This is not a number.',
    invalidQuantity: 'Quantity must be between {0} and {1}.',
    invalidEqualQuantity: 'Please enter a quantity from {0} up to {1}.',
    menuQRCodeClosed: 'Sorry, the menu order QR code is not enabled. Please ask the server for assistance.',
    onlineQRCodeClosed: 'Sorry, Online order QR code disabled by the store.',
    waitlistQRCodeClosed: 'Sorry, Waitlist QR code disabled by the store.',
    tableQRCodeNotClosed: 'Sorry, Table order QR code disabled by the store.',
    quickServiceQRCodeClosed: 'Ordering QR code disabled by the store. Please ask the server for assistance',
    cardExpired: 'Sorry, your credit card has expired. Please update your card details or use another card.',
    cardExpiredError: 'We’re sorry, but this card is not accepted. Please use another card or payment method.',
    confirm: 'OK',
    reload: 'Reload',
    scanToPayUnavailable: 'Sorry, Scan to pay disabled by the store.',
    loginExpire: 'Sorry, Login expired, please login again.'
  },
  zh_CN: {
    loading: '加载中...',
    ok: 'OK',
    cancel: '取消',
    warning: '警告',
    error: '错误',
    submit: '提交',
    save: '保存',
    Subtotal: '小记',
    tax: '税',
    tips: '小费',
    gratuity: '服务费',
    dineIn: '堂食',
    toGo: '外带',
    requestFail: '抱歉，网络连接出现问题，请稍后再试',
    giftCardConfirmed: '该礼品卡已被领取或取消',
    giftCardExpired: '很遗憾，您未能在规定时间内领取该礼品卡，该礼品卡已失效',
    couponAvailableToReturn: '该优惠券仅限老顾客专享，感谢您的理解和支持',
    couponAvailableToNew: '该优惠券仅限新顾客专享，感谢您的理解和支持',
    shareLinkExpired: '抱歉，分享的链接或优惠券已经过期，无法使用',
    shareLinkInvalid: '抱歉，分享的链接无效，无法打开',
    invalidLink: '抱歉，您输入的Yammii链接无效，无法识别。请确认您的链接是否正确或重新获取链接',
    tableQRCodeNotEnabled: '抱歉，您所在的餐厅暂未开启扫码点餐功能。请联系餐厅工作人员，或使用其他方式点餐。',
    quickServiceQRCodeClosed: '抱歉，您所在的餐厅暂未开启扫码点餐功能。请联系餐厅工作人员，或使用其他方式点餐。',
    notInBusinessHours: '抱歉，您选择的商户目前不在营业时间内',
    waitlistNotEnabled: '抱歉，您所在的餐厅暂未开启排位功能。请联系餐厅工作人员，或使用其他方式排位。',
    posWaitlistNotEnabled: 'POS 抱歉，您所在的餐厅的POS系统暂未开启排位功能。请联系餐厅管理人员，或使用其他方式排位。',
    onlineOrderNotEnabled: '抱歉，您所在的餐厅暂未开启在线订单功能。请联系餐厅工作人员，或使用其他方式下单。',
    posOnlineOrderNotEnabled: '抱歉，您所在的餐厅的POS系统暂未开启在线订单功能。请联系餐厅管理人员，或使用其他方式下单。',
    payFail: '抱歉，您的支付没有成功。请检查您的支付信息，或更换其他支付方式。',
    notSupportApplePay: '抱歉，您的手机不支持Apple Pay功能，请更换其他支付方式，或使用其他设备',
    notSupportGooglePay: '抱歉，您的手机不支持Google Pay功能，请更换其他支付方式，或使用其他设备',
    notSupportGiftCard: '抱歉，您选择的餐厅暂未开通礼品卡功能',
    isPaying: '抱歉，您的交易还未完成，正在处理中。请耐心等待，不要重复操作',
    giftCardUnavailable: '抱歉，您想要购买的礼品卡已经下架，无法购买',
    cardNotVerified: '抱歉，您的卡无法验证，请检查您的卡信息，或更换其他卡',
    cvvVerifyFailed: '抱歉，您的卡的CVV验证失败，无法支付。',
    cardPayFail: '抱歉，您的银行将取消这笔交易。请改正错误或使用另一张卡付款。',
    zipCodeVerifyFailed: '抱歉，您输入的邮编无法验证',
    cardCheckFailed: '抱歉，您输入的卡号无法验证，无法支付。请检查您的卡号是否正确，或更换其他卡。',
    cardNumberError: '抱歉，您的支付出现错误。请改正错误，或更换其他支付方式。',
    accountClosed: '很抱歉，您的账户已关闭，无法使用此卡付款。',
    suspectedFraud: '非常抱歉，您的卡片可能存在风险，暂时无法使用。请更换其他卡片或联系发卡银行。',
    avsFailed: '很抱歉， AVS 校验失败，请核对您的卡片信息或更换其他卡片。',
    avsCheckFailed: '您的银行将取消这笔交易。请确认您的卡片有效期和安全码是否正确或更换其他卡片。',
    insufficientFunds: '很遗憾，您的卡片余额不足，无法完成支付。',
    insufficientError: '很抱歉，您的卡片暂时无法使用。请更换其他卡片。',
    notHonor: '抱歉，您的银行拒绝了此次交易。',
    restaurantClose: '很遗憾，您选择的餐厅已经打烊了。',
    orderSubmitted: '很抱歉，您的订单已经提交，无法修改菜品。请确认您的订单信息',
    communicationError: '很抱歉，您的网络出现了问题，无法连接服务器',
    firebaseError: '很抱歉，我们的网络出现了故障，正在努力恢复。请向服务员获取帮助或稍后再试。',
    priceMismatch: '很抱歉，您的订单金额已经更新，无法在线支付。请到柜台结算或刷新页面重新支付。',
    cardTypeMismatch: '很抱歉，您的支付方式不符合要求，无法完成支付。请更换其他支付方式。感谢您的支持和理解。',
    notEnoughPoint: '很遗憾，您的积分不足，无法兑换奖品。',
    unavailableCoupon: '很抱歉，您的优惠券已经过期或不符合使用条件，无法享受优惠',
    giftCardInvalid: '很抱歉，您的礼品卡不存在或无效，无法使用',
    giftCardInsufficient: '很遗憾，您的礼品卡余额不足，无法支付。请充值您的礼品卡或更换其他支付方式。感谢您的支持和理解。',
    userAuthFailed: '很抱歉，礼品卡授权失败，请刷新重试',
    codeExpired: '二维码已过期，请重新扫描。',
    orderPaid: '感谢您的付款，订单已成功支付。',
    orderVoided: '很抱歉，您的订单已被取消。',
    close: '关闭',
    qrCodePayExpired: '对不起，您的支付二维码已失效，请重新生成。',
    numberBetweenRange: '介于{0}和{1}之间的整数',
    noNaN: '请输入有效的数字',
    invalidQuantity: '数量应该大于{0}且小于{1}',
    invalidEqualQuantity: '请输入{0}到{1}之间的数量',
    menuQRCodeClosed: '抱歉，商户关闭了菜单二维码查看功能，请稍后再试。',
    onlineQRCodeClosed: '抱歉，商户关闭了在线订单功能，请稍后再试。',
    waitlistQRCodeClosed: '抱歉，商户关闭了手机排位功能，请稍后再试。',
    tableQRCodeNotClosed: '抱歉，商户关闭了扫码点餐功能，请稍后再试。',
    cardExpired: '您的信用卡已过期，请更新您的支付信息。',
    cardExpiredError: '很抱歉，您的卡无法完成支付，请更换其他卡或支付方式。',
    confirm: '确认',
    reload: '刷新',
    scanToPayUnavailable: '抱歉，商户关闭了远程支付功能，请稍后再试。',
    loginExpire: '抱歉，登录过期，请重新登录后再次支付'
  },
  es_ES: {
    loading: 'Cargando...',
    ok: 'OK',
    cancel: 'Cancelar',
    warning: 'Advertencia',
    error: 'Error',
    submit: 'Enviar',
    save: 'Guardar',
    Subtotal: 'Subtotal',
    tax: 'Impuesto',
    tips: 'Propinas',
    gratuity: 'Gratificación',
    dineIn: 'Comer aquí',
    toGo: 'Llevar',
    requestFail: 'Vaya, hay un problema de red.Por favor, inténtelo de nuevo',
    giftCardConfirmed: 'Esta tarjeta de regalo ya ha sido reclamada o rechazada',
    giftCardExpired: 'El tiempo de reclamación de esta tarjeta de regalo ha pasado y ya no es válida',
    couponAvailableToReturn: 'Este cupón es exclusivo para clientes que vuelven',
    couponAvailableToNew: 'Este cupón es exclusivo para clientes nuevos',
    shareLinkExpired: 'Este enlace o cupón ya no es válido',
    shareLinkInvalid: 'Este enlace ya no es válido',
    invalidLink: 'Enlace Yammii inválido',
    tableQRCodeNotEnabled: 'El escaneo para ordenar está desactivado',
    notInBusinessHours: 'Esta tienda está cerrada ahora',
    waitlistNotEnabled: 'Lista de espera desactivada',
    posWaitlistNotEnabled: 'Esta tienda ha desactivado la función de lista de espera',
    onlineOrderNotEnabled: 'Esta tienda ha desactivado el pedido en línea',
    posOnlineOrderNotEnabled: 'Esta tienda ha desactivado temporalmente el pedido en línea',
    payFail: 'No se ha podido procesar el pago, por favor inténtelo de nuevo más tarde',
    notSupportApplePay: 'Apple Pay no es compatible con su teléfono ',
    notSupportGooglePay: 'Google Pay no es compatible con su teléfono',
    notSupportGiftCard: 'Este restaurante no acepta tarjetas de regalo',
    isPaying: 'Estamos procesando su pago, por favor inténtelo de nuevo más tarde',
    giftCardUnavailable: 'La tarjeta de regalo no está disponible',
    cardNotVerified: 'Su tarjeta de crédito no ha sido verificada',
    cvvVerifyFailed: 'El código CVV que ha introducido es incorrecto',
    cardPayFail: 'Esta transacción será cancelada por su banco. Por favor, corrija el error o utilice otra tarjeta para el pago.',
    zipCodeVerifyFailed: 'El código postal que ha introducido no coincide con la información de su tarjeta',
    cardCheckFailed: 'El número de tarjeta de crédito que ha introducido es incorrecto',
    avsFailed: 'Hubo un problema con la verificación de su dirección. Por favor, compruebe la información de su tarjeta y vuelva a intentarlo.',
    avsCheckFailed: 'Esta transacción será cancelada por su banco1. Por favor, compruebe la fecha de caducidad y el número CVV o utilice otra tarjeta.',
    cardNumberError: 'Por favor, asegúrese de que la información de su tarjeta es correcta o utilice otra tarjeta.',
    accountClosed: 'Lo sentimos, su cuenta está cerrada, por favor utilice otra tarjeta para el pago.',
    suspectedFraud: 'Hay un problema con su tarjeta. Por favor, utilice otra tarjeta o contacte con su banco para obtener ayuda.',
    insufficientFunds: 'No tiene suficientes fondos en su cuenta para completar esta transacción.',
    insufficientError: 'Por favor, utilice otra tarjeta para el pago porque esta tarjeta no es compatible con nuestro sistema.',
    notHonor: 'Su banco ha rechazado esta transacción. Por favor, utilice otra tarjeta o contacte con su banco para más información.',
    restaurantClose: 'La tienda está cerrada por hoy. Por favor, vuelva mañana.',
    orderSubmitted: 'Su pedido ha sido enviado con éxito.',
    communicationError: 'Hay un error de comunicación con el servidor. Por favor, inténtelo de nuevo más tarde.',
    firebaseError: '¡Vaya! Tenemos algunos problemas de red. Estamos intentando restaurar nuestra comunicación de red. Por favor, pida ayuda al servidor o inténtelo de nuevo más tarde. ',
    priceMismatch: 'Debido a un error del sistema, el precio de su pedido ha cambiado2. Le pedimos disculpas por las molestias. Por favor, pague en el mostrador.',
    cardTypeMismatch: 'Hay una discordancia entre el tipo de su tarjeta y su número. Por favor, seleccione el tipo de tarjeta correcto y vuelva a intentarlo.',
    notEnoughPoint: '¡Ya casi está! Necesita más puntos para canjear esta recompensa.',
    unavailableCoupon: 'Este cupón no está disponible en este momento',
    giftCardInvalid: 'Su tarjeta de regalo no es válida, por favor pida ayuda al servidor o utilice otro método de pago.',
    giftCardInsufficient: 'Ha agotado el saldo de su tarjeta de regalo, por favor pida ayuda al servidor o utilice otro método de pago.',
    invalidQuantity: 'La cantidad debe estar entre { 0 } y { 1 }.',
    invalidEqualQuantity: 'Por favor, introduzca una cantidad desde { 0 } hasta { 1 }.',
    menuQRCodeClosed: 'Lo sentimos, el código QR del menú no está habilitado. Por favor, pida ayuda al servidor.',
    onlineQRCodeClosed: 'Lo sentimos, el código QR del pedido en línea está desactivado por la tienda.',
    waitlistQRCodeClosed: 'Lo sentimos, el código QR de la lista de espera está desactivado por la tienda.',
    tableQRCodeNotClosed: 'Lo sentimos, el código QR del pedido en la mesa está desactivado por la tienda.',
    quickServiceQRCodeClosed: 'El código QR de pedidos está desactivado por la tienda3. Por favor, pida ayuda al servidor',
    cardExpired: 'Lo sentimos, su tarjeta de crédito ha caducado. Por favor, actualice los datos de su tarjeta o utilice otra tarjeta.',
    cardExpiredError: 'Lo sentimos, pero esta tarjeta no es aceptada. Por favor, utilice otra tarjeta o método de pago.',
    confirm: 'OK',
    reload: 'Recargar',
    scanToPayUnavailable: 'Lo sentimos, el escaneo para pagar está desactivado por la tienda.',
    loginExpire: 'Lo siento, la sesión ha expirado, por favor inicie sesión nuevamente.'
  }
}
