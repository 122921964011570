import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-layout-view" }
const _hoisted_2 = { class: "body-content" }
const _hoisted_3 = { class: "main-content" }
const _hoisted_4 = {
  key: 0,
  class: "page-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "page-header",
      ref: "headerRef",
      style: _normalizeStyle({ width: _ctx.pageWidthRef })
    }, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ], 4 /* STYLE */),
    _createElementVNode("div", {
      class: _normalizeClass(['page-body', _ctx.bodyBackground]),
      style: _normalizeStyle(_ctx.pageBodyStyle)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ])
      ])
    ], 6 /* CLASS, STYLE */),
    _createElementVNode("div", {
      class: _normalizeClass(['page-footer', _ctx.footerBackground]),
      ref: "footerRef",
      style: _normalizeStyle({ width: _ctx.pageWidthRef })
    }, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true),
      (_ctx.isFullScreenRef)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4))
        : _createCommentVNode("v-if", true)
    ], 6 /* CLASS, STYLE */)
  ]))
}